const loadings = {
  namespaced: true,
  state: {
    loading: false,
    loadingDefault: false
  },
  getters: {
    getLoading (state) {
      return state.loading
    },
    getLoadingDefault (state) {
      return state.loadingDefault
    }
  },
  mutations: {
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    SET_LOADING_DEFAULT (state, loadingDefault) {
      state.loadingDefault = loadingDefault
    }
  },
  actions: {
    setLoading ({ commit }, payload) {
      commit('SET_LOADING', payload)
    },
    setLoadingDefault ({ commit }, payload) {
      commit('SET_LOADING_DEFAULT', payload)
    }
  }
}

export default loadings
