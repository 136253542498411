import AuthenticationService from '@/services/AuthenticationService'
// import alerts from '@/alerts/alert'
// import store from '@/store'

const paket = {
  namespaced: true,
  state: {
    loading: false,
    banners: [],
    packages: [],
    typePackages: [],
    dialogFormEditor: false,
    currentItem: {
      type: ''
    }
  },
  getters: {
    getBanners (state) {
      return state.banners
    }
  },
  mutations: {
    SET_BANNERS (state, banners) {
      state.banners = banners
    },
    SET_CURRENT_ITEM (state, currentItem) {
      state.currentItem.type = currentItem
    },
    SET_TYPE_PACKAGE (state, typePackages) {
      state.typePackages = typePackages
    },
    SET_PACKAGES (state, packages) {
      state.packages = packages
    }
  },
  actions: {
    async getDataBannerPackage ({ commit, state }) {
      state.loading = true
      return await AuthenticationService.get('banners').then(({ data }) => {
        state.loading = false
        // console.log(JSON.stringify(data.data))
        commit('SET_BANNERS', data.data)
      })
    },
    async getDataTypePackage ({ commit }) {
      return await AuthenticationService.get('type-package').then(({ data }) => {
        // console.log(JSON.stringify(data.data))
        commit('SET_CURRENT_ITEM', 'tab-' + data.data[0].type)
        commit('SET_TYPE_PACKAGE', data.data)
      })
    },
    async getDataPackage ({ commit }) {
      return await AuthenticationService.get('packages').then(({ data }) => {
        // console.log(JSON.stringify(data.data))
        commit('SET_PACKAGES', data.data)
      })
    }//
  }
}

export default paket
