const animateScrollObserver = new IntersectionObserver(
  (entries, animateScrollObserver) => {
    entries.forEach((entries, index) => {
      if (entries.isIntersecting) {
        entries.target.classList.add('enter')
        // animateScrollObserver.unobserve(entries.target)
      } else {
        entries.target.classList.remove('enter')
        // animateScrollObserver.unobserve(entries.target)
      }
    })
  }
)

export default {
  bind (el) {
    el.classList.add('before-enter')
    animateScrollObserver.observe(el)
  }
}
