import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)
// const device = localStorage.getItem('device')
const routes = [
  {
    path: '',
    name: '',
    component: () => import('@/layouts/Index.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/desktop/Home.vue')
      },
      {
        path: '/Blog',
        name: 'Blog',
        beforeEnter: () => {
          window.location.href = 'https://blog.solusione.id'
        }
        // redirect: to => {
        //   return 'https://blog.solusione.id'
        // }
        // component: () => import('@/views/desktop/Blog.vue')
      },
      {
        path: '/Package',
        name: 'Package',
        component: () => import('@/views/desktop/Package.vue')
      },
      {
        path: '/Profile',
        name: 'Profile',
        component: () => import('@/views/desktop/Profile.vue')
      },
      {
        path: '/Contact',
        name: 'Contact',
        component: () => import('@/views/desktop/Contact.vue')
      }
    ]
  },
  {
    path: '',
    name: 'index',
    component: () => import('@/layouts/Index2.vue'),
    children: [
      {
        path: '/Services',
        name: 'Services',
        component: () => import('@/views/desktop/Services.vue')
      },
      {
        path: '/Product',
        name: 'Product',
        component: () => import('@/views/desktop/Product.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0
    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }
    return goTo(scrollTo)
  }
})

export default router
