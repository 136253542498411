import axios from 'axios'
import store from '@/store'

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_URL,
    headers: {
      Authorization: `Bearer ${store.getters['auth/token']}`,
      'X-USER-ID': store.getters['auth/user'] ? store.getters['auth/user'].id : '',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Content-Type': 'multipart/form-data'
    }
  })
}
