import alerts from '@/alerts/alert'
import AuthenticationService from '@/services/AuthenticationService'
import store from '@/store'

const auth = {
  namespaced: true,
  state: {
    dialogFormLogin: false,
    formAuth: {
      name: '',
      email: '',
      password: '',
      phone: ''
    },
    dialogFormRegister: false,
    user: null,
    captcha: {
      image: '',
      key: '',
      value: ''
    },
    dialogProfile: false
  },
  getters: {
    getCaptcha (state) {
      return state.banner
    },
    user (state) {
      return state.user
    }
  },
  mutations: {
    SET_DIALOG_LOGIN (state, payload) {
      state.dialogFormLogin = payload
    },
    SET_CAPTCHA (state, captcha) {
      state.captcha = captcha
    },
    SET_USER (state, user) {
      state.user = user
    },
    SET_LOGOUT (state) {
      localStorage.removeItem('auth-user-state')
      alerts.sweetalertToast('success', 'Berhasil Logout')
      state.user = null
      state.dialogProfile = false
    }
  },
  actions: {
    async dialogFormLogin ({ commit, state }, payload) {
      commit('SET_DIALOG_LOGIN', payload)
      return await AuthenticationService.get('captcha/flat').then(({ data }) => {
        state.captcha.image = data.img
        state.captcha.key = data.key
      })
    },
    async reqCaptchaNew ({ state }, payload) {
      return await AuthenticationService.get(`captcha/${payload}`).then(({ data }) => {
        state.captcha.image = data.img
        state.captcha.key = data.key
      })
    },
    async validate ({ commit, state }) {
      store.dispatch('loading/setLoadingDefault', true)
      const params = {
        email: state.formAuth.email,
        password: state.formAuth.password,
        captcha: state.captcha.value,
        key: state.captcha.key
      }
      return await AuthenticationService.post('auth/login', params).then(({ data }) => {
        store.dispatch('loading/setLoadingDefault', false)
        if (data.success) {
          commit('SET_USER', data.data)
          state.dialogFormLogin = false
          alerts.sweetalertToast('success', 'Login Berhasil')
        } else {
          alerts.sweetalertToast('error', 'Login Gagal')
        }
      }).catch(error => {
        store.dispatch('loading/setLoadingDefault', false)
        alerts.sweetalertToast('error', error.response.data.message)
        // console.log('Gagal Di tambahkan', error.response)
      })
    },
    reqLogout ({ commit }) {
      commit('SET_LOGOUT')
    }
  }
}

export default auth
